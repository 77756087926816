'use strict';

(function (undefined) {
	angular.module('clickbooth-common').factory('CountryState', function (Resource, $q, config) {
		var Collection = new Resource({
			url: (config.apiBase || config.api + '/') + 'country-state',
			cache: true
		});
		return Collection;
	}).factory('CityRepository', function (Resource, $q, config) {
		var Collection = new Resource({
			url: (config.apiBase || config.api + '/') + 'cities',
			cache: true
		});

		Collection.getCities = function (data) {
			var request = {
				url: this.options.url,
				method: "GET"
			};

			if (typeof data != "undefined" && data) {
				request.data = data;
			}

			return this.request(request);
		};

		return Collection;
	}).factory('FeatureIntro', function (config, Resource) {
		var resource = new Resource({
			url: (config.apiBase || config.api + '/') + 'feature-intro'
		});

		return resource;
	}).factory('DeviceRepository', function (Resource, config) {
		var Collection = new Resource({
			url: (config.apiBase || config.api + '/') + 'filter',
			cashe: true
		});

		Collection.getDevices = function (data) {
			var request = {
				url: this.options.url + "/devices",
				method: "POST"
			};

			if (typeof data != "undefined" && data) {
				request.data = data;
			}

			return this.request(request);
		};
		return Collection;
	}).factory('FiltersRepository', function (Resource, config) {
		var Collection = new Resource({
			url: (config.apiBase || config.api + '/') + 'filters',
			cache: true
		});

		Collection.getNiches = function (data) {
			var request = {
				url: this.options.url + "/niches",
				method: "POST"
			};

			if (typeof data != "undefined" && data) {
				request.data = data;
			}

			return this.request(request);
		};

		Collection.getVisibilityPresets = function (params) {
			return this.request({
				url: "api/v1/visibility_presets",
				method: "GET",
				params: params
			});
		};

		Collection.getRefferals = function (data) {
			var request = {
				url: this.options.url + "/referrals",
				method: "POST"
			};

			if (typeof data != "undefined" && data) {
				request.data = data;
			}

			return this.request(request);
		};

		Collection.getClonedUsers = function (data) {
			var request = {
				url: this.options.url + "/cloned-users",
				method: "POST"
			};

			if (typeof data != "undefined" && data) {
				request.data = data;
			}

			return this.request(request);
		};

		Collection.getUserRoles = function () {
			return this.request({ url: "api/v1/users/user_roles", "method": 'GET' });
		};

		Collection.getUserVendors = function () {
			return this.request({ url: "api/v1/users/user_vendors", "method": 'GET' });
		};

		Collection.getAffiliates = function (data) {
			var request = {
				url: this.options.url + "/affiliates",
				method: "POST"
			};

			if (typeof data != "undefined" && data) {
				request.data = data;
			}

			return this.request(request);
		};

		Collection.getPivotAffiliates = function (data) {
			var request = {
				url: this.options.url + "/affiliates-pivot",
				method: "POST"
			};

			if (typeof data != "undefined" && data) {
				request.data = data;
			}

			return this.request(request);
		};

		Collection.getCompanies = function (data) {
			var request = {
				url: this.options.url + "/companies",
				method: "POST"
			};

			if (typeof data != "undefined" && data) {
				request.data = data;
			}

			return this.request(request);
		};

		Collection.showUsers = function (data) {
			var request = {
				url: this.options.url + "/users",
				method: "POST"
			};

			if (typeof data != "undefined" && data) {
				request.data = data;
			}

			return this.request(request);
		};

		Collection.getAdvertisers = function (data) {
			var request = {
				url: this.options.url + "/advertisers",
				method: "POST"
			};

			if (typeof data != "undefined" && data) {
				request.data = data;
			}

			return this.request(request);
		};

		Collection.getAdmins = function (data) {
			var request = {
				url: this.options.url + "/admins",
				method: "POST"
			};

			if (typeof data != "undefined" && data) {
				request.data = data;
			}

			return this.request(request);
		};

		var accManagerPromise = null;
		var getAccManagerRequest = function getAccManagerRequest(data) {
			var request = {
				url: Collection.options.url + "/account-managers",
				method: "POST"
			};

			if (typeof data != "undefined" && data) {
				request.data = data;
			}

			return Collection.request(request);
		};

		Collection.getAccountManagers = function (data, use_promise) {
			if (use_promise) {
				if (isUndefinedOrNull(accManagerPromise)) {
					accManagerPromise = getAccManagerRequest(data);
				}

				return accManagerPromise;
			} else {
				return getAccManagerRequest(data);
			}
		};

		Collection.getLinks = function (data) {
			var request = {
				url: this.options.url + "/links",
				method: "POST"
			};

			if (typeof data != "undefined" && data) {
				request.data = data;
			}

			return this.request(request);
		};

		Collection.getCampaigns = function (data) {
			var request = {
				url: this.options.url + "/campaigns",
				method: "POST"
			};

			if (typeof data != "undefined" && data) {
				request.data = data;
			}

			return this.request(request);
		};

		Collection.getTrafficGroups = function (data) {
			var request = {
				url: this.options.url + "/traffic-groups",
				method: "POST"
			};

			if (typeof data != "undefined" && data) {
				request.data = data;
			}

			return this.request(request);
		};

		Collection.getPartnerTrafficTypes = function (data) {
			var request = {
				url: (config.apiBase || config.api + '/') + "merging-partners/get-partner-traffic-types",
				method: "GET"
			};

			if (typeof data != "undefined" && data) {
				request.data = data;
			}

			return this.request(request);
		};

		Collection.getNicheGroups = function (data) {
			var request = {
				url: this.options.url + "/niche-groups",
				method: "POST"
			};

			if (typeof data != "undefined" && data) {
				request.data = data;
			}

			return this.request(request);
		};

		Collection.getBudgetGroups = function (data) {
			var request = {
				url: this.options.url + "/budget-groups",
				method: "POST"
			};

			if (typeof data != "undefined" && data) {
				request.data = data;
			}

			return this.request(request);
		};

		Collection.getDetailedTrafficTypes = function (data) {

			var request = {
				url: "api/v1/link-detailed-traffic-values/filterData",
				method: "GET"
			};

			if (typeof data != "undefined" && data) {
				request.data = data;
			}

			return this.request(request);
		};

		Collection.getPixelGroups = function (data) {
			var request = {
				url: this.options.url + "/pixel-groups",
				method: "POST"
			};

			if (typeof data != "undefined" && data) {
				request.data = data;
			}

			return this.request(request);
		};

		Collection.getUpsells = function (data) {
			var request = {
				url: this.options.url + "/upsells",
				method: "POST"
			};

			if (typeof data != "undefined" && data) {
				request.data = data;
			}

			return this.request(request);
		};

		Collection.getCreativeNames = function (data) {
			var request = {
				url: this.options.url + "/creative-names",
				method: "POST"
			};

			if (typeof data != "undefined" && data) {
				request.data = data;
			}

			return this.request(request);
		};

		Collection.getNetworks = function (data) {
			var request = {
				url: this.options.url + "/networks",
				method: "POST"
			};

			if (typeof data != "undefined" && data) {
				request.data = data;
			}

			return this.request(request);
		};

		Collection.getCustomLinks = function (data) {
			var request = {
				url: "api/v1/custom-links",
				method: "GET"
			};

			if (typeof data != "undefined" && data) {
				request.data = data;
			}

			return this.request(request);
		};

		Collection.getContinents = function (data) {
			var request = {
				url: this.options.url + "/continents",
				method: "POST"
			};

			if (typeof data != "undefined" && data) {
				request.data = data;
			}

			return this.request(request);
		};

		Collection.getRegions = function (data) {
			var request = {
				url: this.options.url + "/regions",
				method: "POST"
			};

			if (typeof data != "undefined" && data) {
				request.data = data;
			}

			return this.request(request);
		};

		Collection.getShieldRules = function (data) {
			var request = {
				url: this.options.url + "/shield-rules",
				method: "POST"
			};

			if (typeof data != "undefined" && data) {
				request.data = data;
			}

			return this.request(request);
		};

		Collection.getBigNiches = function () {
			var request = {
				url: this.options.url + "/big-niches",
				method: "POST"
			};

			return this.request(request);
		};

		Collection.getTags = function () {
			var request = {
				url: this.options.url + "/tags",
				method: "POST"
			};

			return this.request(request);
		};

		var tagsPromise = null;

		Collection.getGroupedTags = function (data) {
			if (isUndefinedOrNull(tagsPromise)) {
				var request = {
					url: this.options.url + "/tags",
					method: "POST"
				};

				if (typeof data != "undefined" && data) {
					request.data = data;
				}

				tagsPromise = this.request(request);
			}

			return tagsPromise;
		};

		return Collection;
	}).service('CustomDomain', function (config, Resource) {
		var collection = new Resource({
			url: config.api + '/custom-domains'
		});

		return collection;
	}).service('Domain', function (config, Resource) {
		var collection = new Resource({
			url: config.api + '/domains'
		});

		return collection;
	}).service('OfferSuggestionService', ['$state', 'Links', 'config', 'Resource', '$rootScope', 'Campaigns', 'OfferSuggestions', '$timeout', '$cookieStore', 'OfferSuggestionsAtrModal', function ($state, Links, Resource, config, $rootScope, Campaigns, OfferSuggestions, $timeout, $cookieStore, OfferSuggestionsAtrModal) {
		var Collection = {};

		Collection.checkTrafficType = function (config) {
			var searchTrafficType = null;
			if ($cookieStore.get('aff_selected_traffic_type_' + config.user.id)) {
				var selected_traffic_type = $cookieStore.get('aff_selected_traffic_type_' + config.user.id);
				searchTrafficType = selected_traffic_type;
			}

			if (config.user.is_ignite) {
				var _selected_traffic_type = { value: "App Installs" };
				searchTrafficType = _selected_traffic_type;
			}

			return searchTrafficType;
		};

		Collection.onSelectSuggestedOffer = function (offer) {
			Collection.createOfferGroupFromBanner(offer);
		};

		Collection.onOfferSuggestionSelectEvent = function (offer, config) {
			var include_traffic_type = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;


			if (typeof offer != "undefined") {

				var traffic_type = offer.campaign_traffic_value_type[0].id;

				var traffic_type_in_cache = Collection.checkTrafficType(config);

				if (traffic_type_in_cache) {
					traffic_type = offer.campaign_traffic_value.includes(traffic_type_in_cache.value) ? traffic_type_in_cache.traffic_type_id : traffic_type;
				}

				if (offer.request_approval === true && offer.request_approval_status !== 2) {
					var atr_modal = OfferSuggestionsAtrModal.create();
					var params = { item: offer };

					if (include_traffic_type === true) {
						params.traffic_type = traffic_type;
					}

					params.active_tab = 'description';
					params.show_create_offer_group = true;

					atr_modal.show(params);
					return;
				}
			}

			Collection.createOfferGroupFromBanner(offer, config, include_traffic_type);
		};

		Collection.loadSuggestedOffers = function () {
			var load_next = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
			var ctrl_this = arguments[1];
			var config = arguments[2];
			var show_banner_if_closed = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;

			ctrl_this.os_settings = $.extend({}, {
				is_floating: true,
				hide_when_no_offers: false,
				show_banner_if_closed: show_banner_if_closed
			}, ctrl_this.os_settings);

			ctrl_this.os_settings.loading_flag = true;
			ctrl_this.os_settings_loaded = false;
			ctrl_this.fetching_recommended = false;

			var params = {
				load_next: load_next
			};

			if (ctrl_this.os_settings.traffic_type) {
				params.traffic_type = ctrl_this.os_settings.traffic_type;
			}

			if (!isUndefinedOrNull(ctrl_this.paginate_offer_suggestion)) {
				params.paginate_by = ctrl_this.paginate_offer_suggestion;
			}

			if (!isUndefinedOrNull(ctrl_this.additionalData)) {
				params.additional_data = true;
			}

			if (!isUndefinedOrNull(ctrl_this.filter_params)) {
				if (!isUndefinedOrNull(ctrl_this.filter_params.status)) {
					params.status = ctrl_this.filter_params.status;
				}
				if (!isUndefinedOrNull(ctrl_this.filter_params.device)) {
					params.device = ctrl_this.filter_params.device;
				}
				if (!isUndefinedOrNull(ctrl_this.filter_params.location)) {
					params.location = ctrl_this.filter_params.location;
				}
				if (!isUndefinedOrNull(ctrl_this.filter_params.traffic_type_id)) {
					params.traffic_type = ctrl_this.filter_params.traffic_type_id;
				}
				if (!isUndefinedOrNull(ctrl_this.filter_params.niche)) {
					params.niche = ctrl_this.filter_params.niche;
				}
				if (!isUndefinedOrNull(ctrl_this.filter_params.niche_groups)) {
					params.niche_groups = ctrl_this.filter_params.niche_groups;
				}
				// Partner UI Multi-select
				// these 3 conditions are added for Partner UI where country, traffic type and
				// verticals are multi-select filters
				if (!isUndefinedOrNull(ctrl_this.filter_params.locations)) {
					params.locations = ctrl_this.filter_params.locations;
				}
				if (!isUndefinedOrNull(ctrl_this.filter_params.traffic_type_ids)) {
					params.traffic_type_ids = ctrl_this.filter_params.traffic_type_ids;
				}
				if (!isUndefinedOrNull(ctrl_this.filter_params.niches)) {
					params.niches = ctrl_this.filter_params.niches;
				}
				if (!isUndefinedOrNull(ctrl_this.filter_params.offer_types)) {
					params.offer_types = ctrl_this.filter_params.offer_types;
				}
				if (!isUndefinedOrNull(ctrl_this.filter_params.detailed_offer_type)) {
					params.detailed_offer_type = ctrl_this.filter_params.detailed_offer_type;
				}
				if (!isUndefinedOrNull(ctrl_this.filter_params.operating_systems)) {
					params.operating_systems = ctrl_this.filter_params.operating_systems;
				}
				// end of Partner UI Multi-select
			}

			ctrl_this.offerSuggestionsService.byNetwork(params).fetching(ctrl_this, 'fetching_recommended').then(function (response) {
				ctrl_this.os_campaigns = [];

				$timeout(function () {
					angular.forEach(response, function (obj) {
						obj.can_create = Campaigns.canUserCreate(obj);
					});
					ctrl_this.os_campaigns = response;
				}, 0, false);
			}, function () {
				ctrl_this.os_settings.hide_load_next = true;
				ctrl_this.os_settings_loaded = true;
			}).then(function () {

				if (ctrl_this.os_campaigns !== undefined) {
					ctrl_this.os_settings.hide = ctrl_this.os_campaigns.filter(function (x) {
						return x.id !== undefined;
					}).length === 0;
				} else {
					ctrl_this.os_settings.hide = false;
				}
				ctrl_this.os_settings.loading_flag = false;
				ctrl_this.os_settings.trigger_refresh = true;
				ctrl_this.os_settings_loaded = true;
			});
		};

		Collection.createOfferGroupFromBanner = function (row, config) {
			var params = {
				serve: 'custom'
			};
			var link_settings = [];
			if (typeof row != "undefined") {
				link_settings.push({ campaign_id: row.id, level: 0 });

				if (row.niche_id) {
					params.niche_id = row.niche_id;
				}
			}

			if (link_settings.length > 0) {
				params.link_settings = angular.copy(link_settings);
			}

			Links.setParams(params);

			if (typeof row != "undefined") {

				var traffic_type = row.campaign_traffic_value[0];

				var traffic_type_in_cache = Collection.checkTrafficType(config);

				if (traffic_type_in_cache) {
					traffic_type = row.campaign_traffic_value.includes(traffic_type_in_cache.value) ? traffic_type_in_cache.value : traffic_type;
				}

				$state.go('offer-group-new', {
					selected_campaign_name: row.name,
					traffic_type: traffic_type,
					offer_id: row.id,
					niche_id: row.niche_id
				});
			}
		};

		return Collection;
	}]).service('CommonService', ['config', 'Resource', function (config, Resource) {
		var model = new Resource({
			url: config.api + '/common'
		});

		model.getSelectedOffers = function (data) {
			var request = {
				url: this.options.url + "/links/get-selected-offers",
				method: 'POST',
				data: data
			};

			return this.request(request);
		};

		return model;
	}]).service('JWTService', ['config', 'Resource', function (config, Resource) {
		var model = new Resource({
			url: config.api
		});

		/**
   * Parsing JWT token to check expiration date
   * @param token
   * @returns json object of parsed token
   */
		model.parseJwt = function (token) {
			var base64Url = token.split('.')[1];
			var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
			var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
				return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
			}).join(''));

			return JSON.parse(jsonPayload);
		};
		/**
   * This is workaround for missing JWT token for React application.
   * New jwt token is created, sent to React app through specific iframe (postMessage)
   * After sending jwt token to React app state needs to be reloaded for reloading React page
   */

		model.createJwtTokenAndReload = function (iframeId, tokenName, user, state) {
			var request = {
				url: this.options.url + '/users/create_token',
				method: 'GET'
			};
			this.request(request).then(function (response) {
				var react_data = model.getReactData(tokenName, user);
				document.getElementById(iframeId).contentWindow.postMessage(react_data, 'https://pcbr.performcb.com');
				state.reload(state.current.name);
			});
		};
		/**
   * Reading JWT token from cookies by name
   */

		model.getTokenFromCookie = function (tokenName) {
			var token = void 0;
			document.cookie.split(";").map(function (item) {
				if (item.includes(tokenName)) {
					token = item.slice(item.indexOf("=") + 1);
				}
			});
			return token;
		};
		/**
   * JSON for react app
   */

		model.getReactData = function (tokenName, user) {
			var configTemp = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
			var fromDashboard = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
			var fromCampaigns = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;

			return {
				"id": 1,
				"token": model.getTokenFromCookie(tokenName),
				"user": user,
				'config': configTemp ? configTemp : config,
				"fromDashboard": fromDashboard,
				"fromCampaigns": fromCampaigns
			};
		};

		/**
   * event properties for Amplitude
   */
		model.getEventProperties = function (page) {
			var eventProperties = {
				"page": page,
				"date": new Date(),
				"ts": new Date().getTime()
			};
			document.cookie.split(";").map(function (item) {
				var items = item.split("=");
				eventProperties[items[0]] = decodeURIComponent(items[1]);
			});
			return eventProperties;
		};
		return model;
	}]);
})();